#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout .site-layout-background {
  background: #fff;
  /* transition: all 5s linear !important; */
}

.site-content {
  padding: 0.5% 1.3%;
  min-height: 91vh;
  transition: all 0.2s linear !important;
  /* position: relative; */
}

.transition-class {
  transition: all 0.3s ease-in-out !important;
}

@keyframes button-beat {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  70% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
  }
}

.collapseiconn {
  position: fixed;
  cursor: pointer;
  top: 50%;
  transform: translate(-50%, 0%);
  height: 35px;
  width: 35px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #8c8c8c;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all 0.25s ease-in-out !important;
}

.collapseiconn:hover {
  background-color: var(--ant-primary-6);
  color: #fff;
}

.collapseiconn:hover::before {
  animation: button-beat 1.5s ease-out infinite;
}

.collapseiconn:hover::after {
  animation: button-beat 1.5s ease-out 0.4s infinite;
}

.collapseiconn::before,
.collapseiconn::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: var(--ant-primary-5);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.25s ease-in-out;
}

.menuSider {
  transition: all 0.2s linear !important;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.menuSider::-webkit-scrollbar {
  display: none;
}

.header-logo {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: fixed !important;
  height: 63px;
  z-index: 1000000;
  transition: all 0.2s linear !important;
}

.header-logo-div {
  width: 205px;
  height: 63px;
}

.sider::-webkit-scrollbar {
  width: 1px;
  background-color: transparent;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  height: 28px !important;
}

.ant-menu-submenu-title {
  height: 28px !important;
}
