:root {
  --antd-header-bg: #374050;
}

.ant-table-thead th.ant-table-cell {
  color: rgb(17, 24, 39);
  font-weight: 600;
  font-size: 12px;
  line-height: 1.35rem;
  text-align: left;
  padding: 8px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  color: black !important;
}

.ant-table-tbody {
  padding: 0 !important;
}
.ant-table-tbody > tr > td {
  padding: 0 !important;
}
