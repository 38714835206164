.buttonClass {
  background-color: transparent;
  border-radius: 15px;
  padding: 0 5px;
  height: 25px;
  font-size: 13px !important;
  cursor: pointer;
  //   border-color: green;
}

.task-details {
  font-size: 15px !important;
  margin-top: 10px;
  //   line-height: 20px;
}

.viewers {
  height: 40px;
  width: 40px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: green;
}

.mright {
  margin-left: -10px;
}

.headingone {
  margin-bottom: 0;
  font-size: 20px !important;
}

.addtaskbutton {
  margin: 10px 0px !important;
  font-size: 15px !important;
  cursor: pointer;
  border: 2px solid #5d6d7e;
  border-radius: 5px;
}

.tasktitle {
  font-size: 18px !important;
  font-weight: 500;
}

