.login-bg-wrapper {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.76), rgba(51, 51, 51, 0.32)); */
  background-color: #27ae60;
  background-position: center;
  background-size: cover;
}

.formdiv {
  background-color: white;
  border-radius: 5px;
  height: 40%;
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.37);
}

.emailinput,
.passwordinput {
  width: 80%;
}

.inputemail,
.inputpassword {
  width: 100%;
  border-radius: 10px !important;
  height: 50px !important;
  /* padding: 5px !important; */
}

.button {
  width: 120px;
  background-color: rgb(48, 48, 52) !important;
  margin-bottom: 20px !important;
}

.divclass {
  width: 80%;
}

p:not('.text-editor') {
  font-weight: 500 !important;
  font-size: 25px !important;
}

@media only screen and (max-width: 1200px) {
  .formdiv {
    height: 40%;
    width: 30%;
  }
}

@media only screen and (max-width: 920px) {
  .formdiv {
    height: 40%;
    width: 70%;
  }
}

@media only screen and (max-width: 820px) {
  .formdiv {
    height: 40%;
    width: 80%;
  }
}

@media only screen and (max-width: 420px) {
  .formdiv {
    height: 38%;
    width: 80%;
  }
}

@media only screen and (max-width: 550px) {
  .formdiv {
    height: 40%;
    width: 80%;
  }
}

@media only screen and (max-width: 380px) {
  .formdiv {
    height: 40%;
    width: 80%;
  }
}

@media only screen and (max-width: 920px) {
  .formdiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
