@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');

:root {
  --border-light: 1px solid #c3c4c7;
  --primary-color: #1890ff;
}

html {
  scrollbar-gutter: stable;
}

body {
  margin: 0;
  width: 100% !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Circular', 'Poppins', sans-serif !important;
  /* word-spacing: -4px !important; */

  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Global CSS */

@media print {
  .pagebreak {
    page-break-before: always;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

.font-pt-sans-narrow {
  font-family: 'PT Sans Narrow', sans-serif;
}

.ant-layout-header {
  /* padding: 0 20px; */
}
@media print {
  table.report {
    page-break-after: auto;
  }
  table.report tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table.report td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table.report thead {
    display: table-header-group;
  }
  table.report tfoot {
    display: table-footer-group;
  }
}
/* @media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
} */
/* .ant-pagination-options {
  margin-left: 0px;
} */

.pagination-changer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-input-number {
  width: 100% !important;
}

.custom-product-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 5px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  background-color: white;
} */
.custom-card {
  display: flex;
  justify-content: center;
  height: 100%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  border-radius: 2px;
  padding: 21px;
  margin: 0;
  background-color: white;
}
.card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 20px;
  margin-bottom: 20px;
}
.card-temp {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 5px;
  width: 100%;
  height: 100px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.ant-form-item {
  margin-bottom: 6px !important;
}

.ant-layout-sider {
  height: auto !important;
  min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(224, 219, 219);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-color: transparent;

  background: grey;
}

.ant-menu-item-selected,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
/* .ant-menu > .ant-menu-submenu-open, */
.ant-menu > .ant-menu-item-selected
/* .ant-menu > .ant-menu-submenu-selected  */ {
  /* background-color: #b039cc !important; */
  font-size: 15px !important;
  font-weight: bold;
  color: black !important;
  /* background-color: gainsboro; */
}

a:hover {
  color: black !important;
}

.menu-icons {
  height: 25px !important;
  width: 25px !important;
  font-size: 16px !important;
}

.anticon svg {
  margin-top: 4px;
}

.ant-menu-submenu-vertical {
  height: 30px !important;
}

.ant-menu-submenu-title:hover {
  background: var(--ant-primary-2);
}

.ant-menu-submenu-vertical .ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
}

.ant-menu-inline-collapsed .ant-menu-submenu-vertical .ant-menu-submenu-title {
  display: block !important;
}

.ant-menu-submenu-vertical .ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: black !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  color: black !important;
}

.hide-pager > .ant-pagination-item,
.hide-pager .ant-pagination-jump-next,
.hide-pager .ant-pagination-jump-prev {
  display: none !important;
}

.historyandliveicon {
  height: 25px;
  width: 25px;
  padding: 0px;
  /* background-color: red; */
  transform: scale(2.3);
  margin-left: 1.8rem;
}

.cardforestimatestock {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 9px;
}

.hoverme:hover {
  box-shadow: 0 2px 4px 0 rgba(24, 7, 7, 0.2);
  transform: scale(1.1);
}

.tableForPos {
  border-collapse: separate;
}

.tableForPos thead th {
  border-bottom: 1px solid gainsboro;
  text-align: left;
}

.tableForPos tbody td {
  border-top: 1px solid gainsboro;
  text-align: left;
}

li.ant-menu-item {
  margin-top: 0px !important;
  height: 30px !important;
}

.ant-table-row .ant-table-cell,
.ant-table-row .ant-table-cell > * {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.ant-table-body .ant-table-row .ant-table-cell,
.ant-table-tbody .ant-table-row .ant-table-cell {
  padding-block: 4px !important;
  padding-inline: 8px !important;
}

@layer components {
  .export-paragraph {
    @apply flex-1 !text-sm font-sans space-x-2;
  }
}

.fake-table-column > * {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.fake-table-column > :first-child {
  background-color: #383f50;
  color: white;
}

.fake-table-column > :last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.fake-table-column:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.form-space:not(:last-child) {
  margin-right: 1rem;
}

.ql-editor {
  min-height: 12.5rem;
}

.text-editor {
  width: 100%;
  margin: 0 auto;
}

.text-editor blockquote {
  border-left: 2px solid #e2e8f0;
  padding: 0.5rem 0 0.5rem 1rem;
  background-color: #f7fafc;
  margin-left: 0;
}

.text-editor :is(h1, h2, h3, h4, h5, h6) {
  margin-bottom: 0.5rem;
}

.text-editor h2 {
  font-size: 1.25rem;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 1em;
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 2em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 2em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 5em;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #f6f6f6;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
}

.chart-box {
  width: 100%;
  padding: 1.5rem 0.5rem 0.5rem 1rem;
  height: 24rem; /* 24rem -paddingY(1rem) */
}

.customize-chart-box {
  width: 100%;
  padding: 1.5rem 0.5rem 1.5rem 1rem;
  height: 24rem; /* 26rem -paddingY(2rem) */
}

.widget.resizing {
  border-color: blue !important;
  transition: border-color 0.2s;
}

.draggable-grid:has(.widget.resizing) .widget:not(.resizing) {
  opacity: 0.7;
  user-select: none;
}

.drawer-select .ant-select-dropdown {
  z-index: 1002 !important;
}

.ant-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

td.ant-table-cell > [data-boolean='true'] {
  color: green;
}

td.ant-table-cell > [data-boolean='false'] {
  color: red;
}

td.ant-table-cell .highlight-number,
td.ant-table-cell .highlight,
td.ant-table-cell.highlight {
  text-decoration: underline;
}

td.ant-table-cell .highlight-number:hover,
td.ant-table-cell .highlight:hover,
td.ant-table-cell.highlight:hover {
  color: blue !important;
}

.ant-table-tbody > tr > td.ant-table-cell-row-hover.highlight {
  color: blue !important;
}

td.ant-table-cell .invoice,
td.ant-table-cell.invoice {
  text-decoration: underline;
}

.ant-tag {
  text-transform: uppercase;
}

.secondary-button {
  background: #fff;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 6px;
}

button.filter-button {
  background: #fff;
  border-radius: 6px;
}

.filter-wrapper > .ant-spin-container {
  height: 100%;
}

.ant-page-header.small-title .ant-page-header-heading-title {
  font-size: 0.9375rem;
}

.ant-image-preview-content > .custom-scale .ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-image-preview-content > .custom-scale .ant-image-preview-img-wrapper img {
  max-height: 400px;
}

.text-wrap-header {
  display: inline-block;
  line-height: 1.5;
  white-space: normal;
  font-size: 13px;
  padding-inline: 4px;
}

.reset-padding {
  padding: 0;
  padding-inline: 0;
}

.custom-grid-input div.antd-grid-input,
.custom-grid-input input.antd-grid-input {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  transition: border-color 0.35s ease-in-out;
}

.custom-grid-input div.antd-grid-input:focus-within,
.custom-grid-input input.antd-grid-input:focus-within {
  border-color: #2196f3;
}

.antd-grid-input div.ant-input-number-input-wrap {
  height: 100%;
}

.antd-grid-input input.ant-input-number-input {
  height: 100%;
}

.original-copy-style {
  position: absolute;
  font-size: 13px;
  font-weight: 300;
  top: -14px;
  right: min(100px, 12.5%);
}

@media screen and (max-width: 500px) {
  .original-copy-style {
    right: 0;
  }
}

/* styles.css */
.price-analysis-table {
  border-collapse: collapse;
  width: 100%;
}

.price-analysis-table th {
  background-color: #e7e6e6;
}

.price-analysis-table th,
.price-analysis-table td {
  padding: 8px;
  text-align: left;
}

.price-analysis-table tbody tr {
  height: 40px;
}

.price-analysis-table th,
.price-analysis-table td {
  border: 1px solid #000;
}

.cron-input {
  width: 55px !important;
}

table.cron-explanation,
table.cron-explanation th,
table.cron-explanation td {
  border-block: 1px solid #d3e2f2;
}

table.cron-explanation th {
  max-width: 13rem !important;
  /* width: 100%; */
  text-align: right;
  padding: 0.2rem 1rem 0.2rem 0;
}

table.cron-explanation td {
  text-align: left;
  padding: 0.2rem 0 0.2rem 1rem;
}

.react-grid-Cell:focus {
  outline: none;
}

.rdg-cell[aria-selected='true'] {
  outline: none;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-now.rbc-current button {
  background-color: #5b5bde;
  color: #f4f4fc;
  border-radius: 50%;
  font-size: 11px;
  width: 18px;
  height: 18px;
}

.rbc-header.rbc-today {
  background-color: #5b5bde !important;
  color: #f4f4fc;
}

.swal-left-align {
  text-align: left !important; /* Ensures all text is left-aligned */
}

.label-w-full label {
  width: 100%;
}
