@media screen and (max-width: 650px) {
  .main-div {
    display: flex !important;
    flex-direction: column !important;
  }

  .left-div {
    width: 100% !important;
  }
}
